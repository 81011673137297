import {TableDefinition} from '../../models';

export const CUSTOM_FIELD_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.CustomField.id',
    prop: 'id',
    visible: false,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.CustomField.name',
    prop: 'name',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.label',
    prop: 'label',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.type',
    prop: 'type',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.active',
    prop: 'isactive',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'NUMBER',
  }
];
