import {TableDefinition} from '../../models';

export const BILLING_DETAIL_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingDetail.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingDetail.tableColumn.element',
    prop: 'packageType',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'STRING',
    sortDisabled: true,
    valueKeyPrefix: 'billing.package'
  },
  {
    key: 'billing.billingDetail.tableColumn.planCost',
    prop: 'planCost',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingDetail.tableColumn.usage',
    prop: 'usage',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingDetail.tableColumn.costPerUse',
    prop: 'costPerUse',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingDetail.tableColumn.additionalCharges',
    prop: 'additionalCharges',
    visible: true,
    objectName: 'BillingDetail',
    sortDisabled: true
  },
  {
    key: 'billing.billingDetail.tableColumn.due',
    prop: 'due',
    visible: true,
    objectName: 'BillingDetail',
    sortDisabled: true
  },
];
