import {TableDefinition} from '../../models';

export const SEGMENT_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Segment.id',
    prop: 'id',
    visible: false,
    objectName: 'Segment',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Segment.name',
    prop: 'name',
    visible: true,
    objectName: 'Segment',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Segment.contacts',
    prop: 'numberOfUsers',
    visible: true,
    objectName: 'Segment',
    sortDisabled: true,
  },
  {
    key: 'API.Segment.status',
    prop: 'activeText',
    visible: true,
    objectName: 'Segment',
    sortDisabled: true,
  },
];
