import {TableDefinition} from '../../models';

export const BILLING_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingList.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'Billing',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.year',
    prop: 'dateFrom',
    dateFormat: 'Y',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.month',
    prop: 'dateFromMonth',
    dateFormat: 'MMMM',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.status',
    prop: 'status',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true,
    valueKeyPrefix: 'billing.billingList.rowValue.status'
  },
  {
    key: 'billing.billingList.tableColumn.totalPayment',
    prop: 'totalCostFormatted',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.updatedAt',
    prop: 'lastModifiedDate',
    dateFormat: 'short',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.updatedBy',
    prop: 'lastModifiedBy',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
];
