import {TableDefinition} from '../../models';

export const LEAD_TABLE_DEFINITION: TableDefinition = [
  { 
    key: 'API.Lead.id', 
    prop: 'id', 
    visible: false, 
    objectName: 'Lead', 
    searchFieldType: 'NUMBER' 
  },
  {
    key: 'API.Lead.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.email',
    prop: 'email',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.phone',
    prop: 'phone',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
];
