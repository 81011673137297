import {TableDefinition} from '../../models';

export const BATCH_JOB_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.BatchJob.id',
    prop: 'uuid',
    visible: false,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.startDate',
    prop: 'startedAt',
    visible: true,
    dateFormat: 'y-MM-dd HH:mm',
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.name',
    prop: 'name',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.startedBy',
    prop: 'userName',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.progress',
    prop: 'progress',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.BatchJob.status.name',
    prop: 'status',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.errors',
    prop: 'errors',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
  }
];
