import {TableDefinition} from '../../models';

export const REPRESENTATIVE_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Representative.id',
    prop: 'id',
    visible: false,
    objectName: 'Representative',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Representative.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Representative',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Representative.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Representative',
    searchFieldType: 'STRING',
  }
];
