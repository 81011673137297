import {TableDefinition} from '../../models';

export const CONTACT_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Contact.id',
    prop: 'id',
    visible: false,
    objectName: 'Contact',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Contact.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.email',
    prop: 'email',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phone',
    prop: 'phone',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phoneNumberStatus',
    prop: 'phoneNumberStatus',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
];

export const CONTACT_DATA_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Contact.id',
    prop: 'id',
    visible: false,
    objectName: 'Contact',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Contact.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.email',
    prop: 'email',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phone',
    prop: 'phone',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  }
];
