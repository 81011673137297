import {TableDefinition} from '../../models';

export const SPECIALIZATION_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Specialization.id',
    prop: 'id',
    visible: false,
    objectName: 'Specialization',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Specialization.name',
    prop: 'name',
    visible: true,
    objectName: 'Specialization',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Specialization.contactListTotal',
    prop: 'contactListTotal',
    visible: true,
    sortDisabled: true,
  },
];
