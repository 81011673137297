import {TableDefinition} from '../../models';

export const GROUP_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Group.id',
    prop: 'id',
    visible: false,
    objectName: 'Group',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Group.name',
    prop: 'name',
    visible: true,
    objectName: 'Group',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Group.contactListTotal',
    prop: 'contactListTotal',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.Group.representativeListTotal',
    prop: 'representativeListTotal',
    visible: true,
    sortDisabled: true,
  },
];
