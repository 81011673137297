import {TableDefinition} from '../../models';

export const MEETING_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Meeting.id',
    prop: 'id',
    visible: false,
    objectName: 'Meeting',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Meeting.title',
    prop: 'title',
    visible: true,
    objectName: 'Meeting',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Presentation.name',
    prop: 'presentation',
    visible: true,
    sortDisabled: true,
    objectName: 'Presentation',
    objectField: 'name',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Meeting.startTimeUTC',
    prop: 'startTimeUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.Meeting.endTimeUTC',
    prop: 'endTimeUTC',
    visible: true,
    dateFormat: 'short',
  },
  { 
    key: 'API.Meeting.duration', 
    prop: 'duration', 
    visible: true 
  },
  {
    key: 'API.Moderator.fullName',
    prop: 'moderator',
    visible: true,
    sortDisabled: true,
    objectName: 'Representative',
    objectField: 'lastName',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Meeting.participantListTotal',
    prop: 'participantListTotal',
    visible: true,
    sortDisabled: true,
  },
];
