import {TableDefinition} from '../../models';

export const WORKPLACE_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Workplace.id',
    prop: 'id',
    visible: false,
    objectName: 'Workplace',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Workplace.name',
    prop: 'name',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.ward',
    prop: 'ward',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.street',
    prop: 'street',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.postalCode',
    prop: 'postalCode',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.city',
    prop: 'city',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
];
