import {TableDefinition} from '../../models';

export const BILLING_PLAN_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingList.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'BillingPlan',
    searchFieldType: 'NUMBER',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.explainer',
    prop: 'explainer',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled: true,
    width: '400px'
  },
  {
    key: 'billing.billingList.tableColumn.status',
    prop: 'status',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled: true,
    valueKeyPrefix: 'billing.billingList.rowValue.status'
  },
  {
    key: 'billing.billingList.tableColumn.billingPeriod',
    prop: 'billingPeriod',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.updatedAt',
    prop: 'lastModifiedDate',
    dateFormat: 'short',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
  {
    key: 'billing.billingList.tableColumn.updatedBy',
    prop: 'lastModifiedBy',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled: true
  },
];
