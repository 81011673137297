import {TableDefinition} from '../../models';

export const MESSAGE_CAMPAIGN_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.MessageCampaign.id',
    prop: 'id',
    visible: false,
    objectName: 'MessageCampaign',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.MessageCampaign.name',
    prop: 'name',
    visible: true,
    objectName: 'MessageCampaign',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.MessageCampaign.typeLabel',
    prop: 'type',
    visible: true,
    valueKeyPrefix: 'API.MessageCampaign.type',
  },
  { 
    key: 'API.MessageCampaign.language', 
    prop: 'language', 
    visible: true 
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfOrderedMessages',
    prop: 'ordered',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfSentMessages',
    prop: 'sent',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfOpenedMessages',
    prop: 'opened',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfCancelledMessages',
    prop: 'canceled',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.dateStartUTC',
    prop: 'dateStartUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.MessageCampaign.lastModifiedAtUTC',
    prop: 'lastModifiedAtUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.MessageCampaign.status',
    prop: 'status',
    visible: true,
    valueKeyPrefix: 'API.MessageCampaign.status',
  },
];
